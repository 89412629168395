<template>
    <CEESAR-View :title="title">

    <CEESAR-Table>
      <template slot="Buttons">
        <CEESAR-Button :label="'New ' + label" icon="add_circle_outline" v-on:click="ShowNewFile()" />
      </template>
      
      <template slot="Filters">
        <CEESAR-TextInputBox label="Label" v-model="filterLabel" :horizontal="true"/>
      </template>

      <CEESAR-TableLine v-for="entity in filteredEntities" :key="entity.id" v-on:click="ShowFile(entity.id)">
        <div>{{entity.label}}</div>

        <template slot="Buttons">
          <CEESAR-Button label="Delete" icon="delete" v-on:click="AskFileDeletionConfirmation(entity.id)"/>
        </template>
      </CEESAR-TableLine>

    </CEESAR-Table>
  </CEESAR-View>
</template>

<script>
export default {
    name: "CEESAR-ModelTable",
    props:{
        title: {type: String, default: "Models"},
        label: {type: String, default: "Model"},
        model: {type: String, required: true},
        fileRoute: {type: String, required: true}
    },
    data(){
        return{
        entities: [],
        filterLabel: ""
        };
    },
    async mounted(){
        await this.LoadEntities();
    },
    
    computed:{
        filteredEntities(){
            if(this.filterLabel == "")
                return this.entities;
            else
            {
                return this.entities.filter(e => { return e.label.toUpperCase().includes(this.filterLabel.toUpperCase()); });
            }
        }
    },
    methods:{
        async LoadEntities(){
            this.entities = await this.$ceesar.backend.GetModelAsync(this.model, "get_all");
        },
        ShowFile(pId){
            this.$router.push({ path: this.fileRoute + "/" + pId });
        },
        ShowNewFile(){
            this.$router.push(this.fileRoute);
        },

        async AskFileDeletionConfirmation(pId)
        {
            this.$ceesar.design.registerDialogEvent("Answer", (pAnswer) => this.confirmFileDeletion(pAnswer, pId));

            this.$ceesar.design.showConfirm(
                "This " + this.label + " is about to be deleted. Do you want to continue?",
                [
                {label: "Confirm", value: true, icon: "check"},
                {label: "Cancel", value: false, icon: "cancel"},
                ]
            );
        },
        async confirmFileDeletion(pDialogAnswer, pId)
        {
        this.$ceesar.design.unregisterDialogEvent("Answer");

        if(!pDialogAnswer) return;

        let res = await this.$ceesar.backend.DeleteModelAsync(this.model, pId);
            if(res.code < 0)
            {
                // Capture DB integrity constraint violation
                if(res.hasOwnProperty("data") && res.data.hasOwnProperty("errorInfo"))
                {
                    if(res.data.errorInfo[0] == 23000)
                    {
                        let message = "Unable to perform deletion. This " + this.label + " is used in another table.";
                        this.$ceesar.design.showToast(message, "error");
                        return;
                    }
                    
                }

                this.$ceesar.design.showToast(res.message, "error");
                return;
            }
            await this.LoadEntities();
        },
    }
}
</script>

<style>
</style>